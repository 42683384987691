<template>
  <tr data-row-id="u0" class="mdc-data-table__row">
    <td class="mdc-data-table__cell mdc-data-table__cell--checkbox" v-if="featureToggles.dashboardMultiSessionSelect"
      :style="tableStyle">
    </td>
    <td class="mdc-data-table__cell font-monospace" :style="tableStyle" :title="session.id">{{ shortenId(session.id) }}
    </td>
    <td class="mdc-data-table__cell" :style="tableStyle">
      <div class="form-control" :class="{ invalid: sessionNameValidity === 'invalid' || !isUniqueSessionName }">
        <input type="text" v-model.trim="sessionName" class="font-monospace table-input" :style="tableInputStyle">
        <p class="error" v-if="sessionNameValidity === 'invalid'" :style="[errorStyle, fontStyle]">{{
            tr('Name cannot contain any of the following characters: ')
        }}<br>! @ # $ % ^ &amp; * [ ] { } ; : ' " &lt; &gt; \ / |</p>
        <p class="error" v-if="!isUniqueSessionName" :style="[errorStyle, fontStyle]">{{
            tr('Your Session Name must be unique.')
        }}</p>
      </div>
    </td>
    <td class="mdc-data-table__cell" :style="tableStyle">
      <router-link :to="sessionUrl(session.ownerName, session.name, session.id, isUniqueSessionName)">
        <go-to-session-icon class="session-icon"></go-to-session-icon>
      </router-link>
    </td>
    <td class="mdc-data-table__cell" :style="[tableStyle, fontStyle]">{{ session.toolCount }}</td>
    <td class="mdc-data-table__cell mdc-data-table__cell--checkbox" :style="tableStyle">
      <check-box :is-checked="locallyAnonymized" @checked="onLocallyAnonymized">
      </check-box>
    </td>
    <td class="mdc-data-table__cell" :style="tableStyle">
      <div class="toast" v-if="showToast" :style="[toastStyle, fontStyle]">
        {{ tr('Successfully copied!') }}
      </div>
      <copy-session-link-icon class="session-icon copy-session-link-icon" v-if="featureToggles.enableSessionSharing"
        @click.prevent="onCopyToClipboard(session)" role="button" tabindex="0"
        @keyup.enter="onCopyToClipboard(session)"></copy-session-link-icon>
    </td>
    <td class="mdc-data-table__cell" v-if="featureToggles.enableSessionSharing" :style="[tableStyle, fontStyle]">
      <table class="sharing-cell">
        <tr>
          <td v-if="session.collaborateWithAnyoneLink" style="vertical-align: middle">*</td>
          <td v-else style="vertical-align: middle">
            {{ session.collaborators.length }}
          </td>
          <td>
            <edit-collaborators-icon class="session-icon" @click.prevent="onOpenCollaboratorsModal(session.id)"
              role="button" tabindex="0" @keyup.enter="onOpenCollaboratorsModal(session.id)"
              :title="tr('Add or remove collaborators')"></edit-collaborators-icon>
          </td>
        </tr>
      </table>
    </td>
    <td class="mdc-data-table__cell"
      v-if="featureToggles.enableSessionSharing && featureToggles.enableSessionReadOnlySharing"
      :style="[tableStyle, fontStyle]">
      <table class="sharing-cell">
        <tr>
          <td v-if="session.viewerWithAnyoneLink" style="vertical-align: middle">*</td>
          <td v-else style="vertical-align: middle">
            {{ session.viewers.length }}
          </td>
          <td>
            <edit-viewers-icon class="session-icon" @click.prevent="onOpenViewersModal(session.id)" role="button"
              tabindex="0" @keyup.enter="onOpenViewersModal(session.id)" :title="tr('Add or remove viewers')">
            </edit-viewers-icon>
          </td>
        </tr>
      </table>
    </td>
    <td class="mdc-data-table__cell" :style="[tableStyle, fontStyle]" :title="lastAccessedDate(session.lastAccessed)">{{
        formatLastAccessed(session.lastAccessed)
    }}</td>
    <td class="mdc-data-table__cell" :style="tableStyle">
      <duplicate-session-icon class="session-icon" @click.prevent="onDuplicateSession(session)" role="button"
        tabindex="0" @keyup.enter="onDuplicateSession(session)"></duplicate-session-icon>
    </td>
    <td class="mdc-data-table__cell" :style="tableStyle">
      <delete-session-icon class="session-icon" @click.prevent="onConfirmDeleteSession(session)" role="button"
        tabindex="0" @keyup.enter="onConfirmDeleteSession(session)"></delete-session-icon>
    </td>
  </tr>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

import CheckBox from '@/components/ui/CheckBox/CheckBox.vue'
import CopySessionLinkIcon from '@/components/ui/icons/CopySessionLinkIcon.vue'
import DeleteSessionIcon from '@/components/ui/icons/DeleteSessionIcon.vue'
import DuplicateSessionIcon from '@/components/ui/icons/DuplicateSessionIcon.vue'
import EditCollaboratorsIcon from '@/components/ui/icons/EditCollaboratorsIcon.vue'
import EditViewersIcon from '@/components/ui/icons/EditViewersIcon.vue'
import GoToSessionIcon from '@/components/ui/icons/GoToSessionIcon.vue'
import { calculateLastAccessDate, isThemeLight, nameIsValidRE, sanitizeUrlSegment, shortenId, themeColors, useDebouncedRef } from '@/common/shared.js'

import { sessionUrl, tableInputStyle, tableStyle } from './tables.js'

export default {
  components: {
    CheckBox,
    CopySessionLinkIcon,
    DeleteSessionIcon,
    DuplicateSessionIcon,
    EditCollaboratorsIcon,
    EditViewersIcon,
    GoToSessionIcon,
  },
  props: {
    formatLastAccessed: {
      type: Function,
      required: true,
    },
    onDuplicateSession: {
      type: Function,
      required: true,
    },
    onOpenCollaboratorsModal: {
      type: Function,
      required: true,
    },
    onOpenDeleteSessionModal: {
      type: Function,
      required: true,
    },
    onOpenViewersModal: {
      type: Function,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
    setDeleteSessionConfirmFunc: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const featureToggles = computed(() => store.getters.featureToggles)
    const store = useStore()
    const tr = inject('tr')

    const locallyAnonymized = ref(store.getters.profile.sessions[props.sessionId].locallyAnonymized)
    const confirmBeforeDeletingSession = ref(store.getters.profile.confirmBeforeDeletingSession)

    const session = reactive(store.getters.profile.sessions[props.sessionId])
    watch(store.getters.profile.sessions[props.sessionId], (newValue) => {
      session.value = newValue
      if (session.value.name !== sessionName.value) {
        sessionName.value = session.value.name
      }
      if (session.value.locallyAnonymized !== locallyAnonymized.value) {
        locallyAnonymized.value = session.value.locallyAnonymized
      }
    })

    const checkboxColor = computed(() => store.getters.checkboxColor)

    const checkboxOutline = computed(() => {
      return {
        outlineColor: isThemeLight[store.getters.currentThemeName] ? '#000' : '#fff',
      }
    })

    const errorStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].errorBackgroundColor,
        color: themeColors[store.getters.currentThemeName].errorTextColor,
      }
    })

    const toastStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].confirmationPopupBackgroundColor,
        color: themeColors[store.getters.currentThemeName].confirmationPopupTextColor,
      }
    })

    const showToast = ref(false)
    const onCopyToClipboard = (session) => {
      const url = sessionUrl(session.ownerName, session.name, session.id, isUniqueSessionName.value)
      navigator.clipboard.writeText(`${location.origin}${url}`)

      showToast.value = true
      setTimeout(() => {
        showToast.value = false
      }, 1000) // show toast for 1 sec.
    }

    const onDeleteSession = (session) => {
      session.collaborators.forEach((userId) => {
        store.dispatch('removeSessionSharing', {
          ownerId: session.ownerId,
          sessionId: session.id,
          userId,
          publish: true,
        })
      })
      session.viewers.forEach((userId) => {
        store.dispatch('removeSessionSharing', {
          ownerId: session.ownerId,
          sessionId: session.id,
          userId,
          publish: true,
        })
      })
      // Also remove session from profile of this user:
      store.dispatch('removeSessionSharing', {
        ownerId: session.ownerId,
        sessionId: session.id,
        userId: session.ownerId,
        publish: true,
      })
      // Delete the session data.
      store.dispatch('deleteSession', {
        ownerId: session.ownerId,
        sessionId: session.id,
        publish: true,
      })
    }

    const onConfirmDeleteSession = (session) => {
      if (confirmBeforeDeletingSession.value) {
        props.setDeleteSessionConfirmFunc(() => {
          onDeleteSession(session)
        })
        props.onOpenDeleteSessionModal()
        return
      }
      onDeleteSession(session)
    }

    const sessionNameValidity = ref('pending')

    const sessionName = useDebouncedRef(session.name, 500)
    watch(sessionName, (newValue) => {
      if (newValue && !nameIsValidRE.test(newValue)) {
        sessionNameValidity.value = 'invalid'
        return
      }
      sessionNameValidity.value = 'valid'
      if (session.name === newValue) { return }
      const msg = {
        ownerId: session.ownerId,
        sessionId: session.id,
        sessionName: newValue,
        userId: session.ownerId,
        publish: true,
      }
      store.dispatch('setSessionName', msg)
    })

    const onLocallyAnonymized = (newValue) => {
      if (session.locallyAnonymized === newValue) { return }
      const msg = {
        ownerId: session.ownerId,
        sessionId: session.id,
        locallyAnonymized: newValue,
        publish: true,
      }
      store.dispatch('setSessionLocallyAnonymized', msg)
    }

    const isUniqueSessionName = computed(() => {
      if (!sessionName.value) { return true }
      const newName = sanitizeUrlSegment(session.name)
      return store.getters.sessionsOwnedByMe.reduce((acc, s) => {
        if (!s.name || s.id === session.id) { return acc }
        const differ = sanitizeUrlSegment(s.name) !== newName
        return acc && differ
      }, true)
    })

    const fontStyle = computed(() => {
      return {
        fontFamily: store.getters.font,
      }
    })

    const lastAccessedDate = (timeOfLastAccess) => calculateLastAccessDate(timeOfLastAccess, store)

    return {
      checkboxColor,
      checkboxOutline,
      errorStyle,
      featureToggles,
      fontStyle,
      isUniqueSessionName,
      lastAccessedDate,
      locallyAnonymized,
      onConfirmDeleteSession,
      onCopyToClipboard,
      onLocallyAnonymized,
      session,
      sessionName,
      sessionNameValidity,
      sessionUrl,
      shortenId,
      showToast,
      tableInputStyle,
      tableStyle,
      toastStyle,
      tr,
    }
  }
}
</script>

<style lang="scss" scoped>
@import './tables.scss';
</style>