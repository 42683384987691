<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    :fill="modalIconColor"
  >
    <title>{{ tr('Delete this session') }}</title>
    <path d="M300 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM464 80h-82.41l-34-56.7A48 48 0 0 0 306.41 0H205.59a48 48 0 0 0-41.16 23.3L130.41 80H48a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM203.84 50.91A6 6 0 0 1 209 48h94a6 6 0 0 1 5.15 2.91L325.61 80H186.39zM400 464H112V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z" />
  </svg>
</template>


<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js';

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].modalIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  }
}
</script>
