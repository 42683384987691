<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    :fill="modalIconColor"
  >
    <title>{{ tr('Copy session link for sharing') }}</title>
    <path d="m465.941 65.941-51.882-51.882A48 48 0 0 0 380.118 0H208c-26.51 0-48 21.49-48 48v48H80c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM298 464H86a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H214a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243z" />
  </svg>
</template>


<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js';

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].modalIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  }
}
</script>
